var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:_vm.$style.root},[(_vm.$route.meta.title)?_c('h1',{staticClass:"mb-4"},[_vm._v(_vm._s(_vm.$route.meta.title)+" #"+_vm._s(_vm.id))]):_vm._e(),_c('div',{staticClass:"mb-4 clearfix"},[_c('VBtn',{attrs:{"depressed":""},on:{"click":_vm.toList}},[_c('VIcon',{domProps:{"textContent":_vm._s('mdi-arrow-left')}}),_vm._v(" Назад ")],1)],1),_c('ApplicationList',{attrs:{"headers":[
        { text: 'ID', value: 'id', sortable: false },
        { text: 'Дата создания', value: 'date', sortable: false },
        { text: 'Даты проведения инспекции', value: 'inspection', sortable: false },
        { text: 'Статус', value: 'status.name', alias: 'status', options: _vm.statuses, sortable: false },
        { text: 'Площадка', value: 'company', sortable: false },
        { text: 'Цех заказчика', value: 'workshop', sortable: false },
        { text: 'Автор заявки', value: 'author', sortable: false },
        { text: 'Согласующий', value: 'reviewer', sortable: false },
        { text: 'Инспектор', value: 'inspector', alias: 'companyName', sortable: false },
        { text: 'Категория товара', value: 'product', alias: 'productCategory', sortable: false },
        { text: 'Виды инспекций', value: 'type', alias: 'inspectionType', sortable: false },
      ],"items":_vm.list.items,"count":_vm.list.count,"page":_vm.page,"size":_vm.size,"loading":_vm.loading}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }