<template>
  <div :class="$style.root">
    <h1 v-if="$route.meta.title" class="mb-4">{{$route.meta.title}} #{{ id }}</h1>
    <div class="mb-4 clearfix">
      <VBtn depressed @click="toList">
        <VIcon v-text="'mdi-arrow-left'" />
        Назад
      </VBtn>
    </div>
    <ApplicationList
        :headers="[
          { text: 'ID', value: 'id', sortable: false },
          { text: 'Дата создания', value: 'date', sortable: false },
          { text: 'Даты проведения инспекции', value: 'inspection', sortable: false },
          { text: 'Статус', value: 'status.name', alias: 'status', options: statuses, sortable: false },
          { text: 'Площадка', value: 'company', sortable: false },
          { text: 'Цех заказчика', value: 'workshop', sortable: false },
          { text: 'Автор заявки', value: 'author', sortable: false },
          { text: 'Согласующий', value: 'reviewer', sortable: false },
          { text: 'Инспектор', value: 'inspector', alias: 'companyName', sortable: false },
          { text: 'Категория товара', value: 'product', alias: 'productCategory', sortable: false },
          { text: 'Виды инспекций', value: 'type', alias: 'inspectionType', sortable: false },
        ]"
        :items="list.items"
        :count="list.count"
        :page="page"
        :size="size"
        :loading="loading"
    />
  </div>
</template>

<script>
import { debounce, values, get } from 'lodash-es';
import { mapGetters, mapActions } from 'vuex';
import { APPLICATION_STATUSES as STATUSES, USER_TYPES } from '@/store/inspect/enums';
import ApplicationList from '@/components/inspect/ApplicationList/ApplicationList';
export default {
  name: 'InspectorDetailView',
  components: {
    ApplicationList,
  },
  props: {
    id: { type: String },
    page: { type: Number, default: 1 },
    size: { type: Number, default: 20 },
    filter: { type: Object }
  },
  computed: {
    ...mapGetters({
      loading: 'inspect/pending',
      list: 'inspect/applications',
      hasRole: 'user/hasRole',
    }),
    statuses() {
      return values(STATUSES);
    },
    isReviewer() {
      return this.hasRole(get(USER_TYPES, 'REVIEWER.value', 'REVIEWER'), 'inspect');
    },
    isInitiator() {
      return this.hasRole(get(USER_TYPES, 'INITIATOR.value', 'INITIATOR'), 'inspect');
    },
    haveActiveComments() {
      return get(this.filter, ['haveActiveComments']) === 'Y';
    },
  },
  methods: {
    ...mapActions({
      createApplication: 'inspect/createApplication',
      fetchApplicationList: 'inspect/fetchApplicationList',
    }),
    onCreate() {
      return this.createApplication().then((application) => {
        const id = application.id;
        this.$notify({
          type: 'success',
          title: 'Успех',
          text: 'Заявка успешно создана',
        });
        if (id) this.$router.push({ name: 'inspect/ApplicationDetailView', params: { application: id }});
        return application;
      });
    },
    onUpdateState: debounce(function() {
      const { page, size, filter } = this;
      return this.fetchApplicationList({ page, size, filter });
    }, 500),
    toList() {
      this.$router.back();
    },
    toggleFilterComments() {
      const { query } = this.$route;
      const value = this.haveActiveComments ? 'N' : 'Y';
      this.$router.push({ name: 'inspect/ApplicationListView', query: { ...query, haveActiveComments: value } });
    }
  },
  watch: {
    page: function() {
      this.onUpdateState();
    },
    size: function() {
      this.onUpdateState();
    },
    filter: {
      deep: true,
      handler: function() {
        this.onUpdateState();
      }
    }
  },
  mounted() {
    this.onUpdateState();
  }
}
</script>

<style module lang="scss">
.root {}
</style>
